import {
  EthereumDistribute__factory,
  GochainSwap__factory,
  SimpleERC20Token__factory,
  WOMISwapToOMI__factory,
} from '../types/contracts'
import { throwIfUndefined } from './errors'

export enum ChainId {
  // Used for initial state
  'UNDEFINED' = 0,
  'GOCHAIN_MAINNET' = 60,
  'GOCHAIN_TESTNET' = 31337,
  'ETHEREUM_MAINNET' = 1,
  'ETHEREUM_TESTNET' = 4,
}

export const getChainId = (chainId?: number): ChainId => {
  switch (chainId) {
    case 1:
      return ChainId.ETHEREUM_MAINNET
    case 4:
      return ChainId.ETHEREUM_TESTNET
    case 60:
      return ChainId.GOCHAIN_MAINNET
    case 31337:
      return ChainId.GOCHAIN_TESTNET
    default:
      throw new Error(`Unsupported chain id ${chainId}`)
  }
}

export enum ContractName {
  'OMI' = 'omi',
  'WOMI' = 'womi',
  'WOMI_SWAP' = 'womiSwap',
  'SWAP' = 'swap',
  'DISTRIBUTE' = 'distribute',
}

const ContractDataByChainId: {
  [key in ChainId]: {
    [key in ContractName]?: {
      address: string
      abi: any
      deploymentBlock: number
    }
  }
} = {
  // Needed for type checking
  [ChainId.UNDEFINED]: {},
  [ChainId.ETHEREUM_MAINNET]: {
    [ContractName.OMI]: {
      address: '',
      abi: SimpleERC20Token__factory.abi,
      deploymentBlock: -1000,
    },
    [ContractName.WOMI]: {
      address: '0x04969cd041c0cafb6ac462bd65b536a5bdb3a670',
      abi: SimpleERC20Token__factory.abi,
      deploymentBlock: 12146612,
    },
    [ContractName.DISTRIBUTE]: {
      address: '',
      abi: EthereumDistribute__factory.abi,
      deploymentBlock: -1000,
    },
    [ContractName.WOMI_SWAP]: {
      address: '',
      abi: WOMISwapToOMI__factory.abi,
      deploymentBlock: -1000,
    },
  },
  [ChainId.ETHEREUM_TESTNET]: {
    [ContractName.OMI]: {
      address: '0x800Af85736d460EE28d2523bD7bc895347523Aeb',
      abi: SimpleERC20Token__factory.abi,
      deploymentBlock: 9279230,
    },
    [ContractName.WOMI]: {
      address: '0x2fa448E1dF0408a0398FA1A26D4A44F375a16380',
      abi: SimpleERC20Token__factory.abi,
      deploymentBlock: 9279230,
    },
    [ContractName.DISTRIBUTE]: {
      address: '0x26AE1cD2799744831fc2aA5cF17fB02F6A17143E',
      abi: EthereumDistribute__factory.abi,
      deploymentBlock: 9279230,
    },
    [ContractName.WOMI_SWAP]: {
      address: '0xe357b616d7a10ddFCB10b28665eb85Ea436697F8',
      abi: WOMISwapToOMI__factory.abi,
      deploymentBlock: 9279230,
    },
  },
  [ChainId.GOCHAIN_MAINNET]: {
    [ContractName.OMI]: {
      address: '0x5347FDeA6AA4d7770B31734408Da6d34a8a07BdF',
      abi: SimpleERC20Token__factory.abi,
      deploymentBlock: 6204394,
    },
    [ContractName.SWAP]: {
      address: '',
      abi: GochainSwap__factory.abi,
      deploymentBlock: -1000,
    },
  },
  [ChainId.GOCHAIN_TESTNET]: {
    [ContractName.OMI]: {
      address: '0x02f80DA373021E0b6A0a00470f82b55d4830A724',
      abi: SimpleERC20Token__factory.abi,
      deploymentBlock: 20225277,
    },
    [ContractName.SWAP]: {
      address: '0x37bBe94e81f31f3B8Ba88eABA78fa3f2db137649',
      abi: GochainSwap__factory.abi,
      deploymentBlock: 17372992,
    },
  },
}

// This is verbose, but there isn't a good way to iterate over the ContractDataByChainId object to populate this map
export const abiMap = new Map([
  [
    ContractDataByChainId[ChainId.ETHEREUM_MAINNET].omi?.address ?? '',
    ContractDataByChainId[ChainId.ETHEREUM_MAINNET].omi?.abi ?? '',
  ],
  [
    ContractDataByChainId[ChainId.ETHEREUM_MAINNET].womi?.address ?? '',
    ContractDataByChainId[ChainId.ETHEREUM_MAINNET].womi?.abi ?? '',
  ],
  [
    ContractDataByChainId[ChainId.ETHEREUM_MAINNET].distribute?.address ?? '',
    ContractDataByChainId[ChainId.ETHEREUM_MAINNET].distribute?.abi ?? '',
  ],
  [
    ContractDataByChainId[ChainId.ETHEREUM_MAINNET].womiSwap?.address ?? '',
    ContractDataByChainId[ChainId.ETHEREUM_MAINNET].womiSwap?.abi ?? '',
  ],
  [
    ContractDataByChainId[ChainId.ETHEREUM_TESTNET].omi?.address ?? '',
    ContractDataByChainId[ChainId.ETHEREUM_TESTNET].omi?.abi ?? '',
  ],
  [
    ContractDataByChainId[ChainId.ETHEREUM_TESTNET].womi?.address ?? '',
    ContractDataByChainId[ChainId.ETHEREUM_TESTNET].womi?.abi ?? '',
  ],
  [
    ContractDataByChainId[ChainId.ETHEREUM_TESTNET].distribute?.address ?? '',
    ContractDataByChainId[ChainId.ETHEREUM_TESTNET].distribute?.abi ?? '',
  ],
  [
    ContractDataByChainId[ChainId.ETHEREUM_TESTNET].womiSwap?.address ?? '',
    ContractDataByChainId[ChainId.ETHEREUM_TESTNET].womiSwap?.abi ?? '',
  ],
  [
    ContractDataByChainId[ChainId.GOCHAIN_MAINNET].omi?.address ?? '',
    ContractDataByChainId[ChainId.GOCHAIN_MAINNET].omi?.abi ?? '',
  ],
  [
    ContractDataByChainId[ChainId.GOCHAIN_MAINNET].swap?.address ?? '',
    ContractDataByChainId[ChainId.GOCHAIN_MAINNET].swap?.abi ?? '',
  ],
  [
    ContractDataByChainId[ChainId.GOCHAIN_TESTNET].omi?.address ?? '',
    ContractDataByChainId[ChainId.GOCHAIN_TESTNET].omi?.abi ?? '',
  ],
  [
    ContractDataByChainId[ChainId.GOCHAIN_TESTNET].swap?.address ?? '',
    ContractDataByChainId[ChainId.GOCHAIN_TESTNET].swap?.abi ?? '',
  ],
])

export const getContractAddress = ({
  contractName,
  chainId,
}: {
  contractName: ContractName
  chainId: ChainId
}): string => {
  const address = ContractDataByChainId[chainId][contractName]?.address
  if (!address) {
    throw new Error(`${contractName} not deployed on chain ${chainId}`)
  }
  return address
}

export const getContractDeploymentBlock = ({
  contractName,
  chainId,
}: {
  contractName: ContractName
  chainId: ChainId
}): number => {
  const deploymentBlock =
    ContractDataByChainId[chainId][contractName]?.deploymentBlock
  if (!deploymentBlock) {
    throw new Error(`${contractName} not deployed on chain ${chainId}`)
  }
  return deploymentBlock
}

export const getBlockExplorerUrl = (
  transactionHash: string,
  chainId?: ChainId
): string => {
  switch (chainId) {
    case ChainId.ETHEREUM_MAINNET:
      return `https://etherscan.io/tx/${transactionHash}`
    case ChainId.ETHEREUM_TESTNET:
      return `https://rinkeby.etherscan.io/tx/${transactionHash}`
    case ChainId.GOCHAIN_MAINNET:
      return `https://explorer.gochain.io/tx/${transactionHash}`
    case ChainId.GOCHAIN_TESTNET:
      return `https://testnet-explorer.gochain.io/tx/${transactionHash}`
    default:
      throw new Error(`Explorer URL not configured for chain ${chainId}`)
  }
}

const baseNames: { [key in ChainId]: 'ethereum' | 'gochain' | undefined } = {
  [ChainId.UNDEFINED]: undefined,
  [ChainId.ETHEREUM_MAINNET]: 'ethereum',
  [ChainId.ETHEREUM_TESTNET]: 'ethereum',
  [ChainId.GOCHAIN_MAINNET]: 'gochain',
  [ChainId.GOCHAIN_TESTNET]: 'gochain',
}

const basePrintNames: { [key in ChainId]: string | undefined } = {
  [ChainId.UNDEFINED]: undefined,
  [ChainId.ETHEREUM_MAINNET]: 'Ethereum',
  [ChainId.ETHEREUM_TESTNET]: 'Ethereum',
  [ChainId.GOCHAIN_MAINNET]: 'Gochain',
  [ChainId.GOCHAIN_TESTNET]: 'Gochain',
}

const printNames: { [key in ChainId]: string | undefined } = {
  [ChainId.UNDEFINED]: undefined,
  [ChainId.ETHEREUM_MAINNET]: 'Ethereum Mainnet',
  [ChainId.ETHEREUM_TESTNET]: 'Ethereum Rinkeby',
  [ChainId.GOCHAIN_MAINNET]: 'Gochain Mainnet',
  [ChainId.GOCHAIN_TESTNET]: 'Gochain Testnet',
}

const oppositeChainIds: { [key in ChainId]: ChainId | undefined } = {
  [ChainId.UNDEFINED]: undefined,
  [ChainId.ETHEREUM_MAINNET]: ChainId.GOCHAIN_MAINNET,
  [ChainId.ETHEREUM_TESTNET]: ChainId.GOCHAIN_TESTNET,
  [ChainId.GOCHAIN_MAINNET]: ChainId.ETHEREUM_MAINNET,
  [ChainId.GOCHAIN_TESTNET]: ChainId.ETHEREUM_TESTNET,
}

export const isGochain = (chainId: ChainId) => baseNames[chainId] === 'gochain'

export const isEthereum = (chainId: ChainId) =>
  baseNames[chainId] === 'ethereum'

export const getOppositeChainId = (chainId: ChainId) =>
  throwIfUndefined(oppositeChainIds[chainId])

export const getChainBaseName = (chainId: ChainId) =>
  throwIfUndefined(baseNames[chainId])

export const getChainPrintName = (chainId: ChainId) =>
  throwIfUndefined(printNames[chainId])

export const getChainBasePrintName = (chainId: ChainId) =>
  throwIfUndefined(basePrintNames[chainId])

const chainCurrencies: { [key in ChainId]: string | undefined } = {
  [ChainId.UNDEFINED]: undefined,
  [ChainId.ETHEREUM_MAINNET]: 'ETH',
  [ChainId.ETHEREUM_TESTNET]: 'ETH',
  [ChainId.GOCHAIN_MAINNET]: 'GO',
  [ChainId.GOCHAIN_TESTNET]: 'GO',
}

export const getChainCurrency = (chainId: ChainId) =>
  throwIfUndefined(chainCurrencies[chainId])

const tokenNames: { [key in ChainId]: string | undefined } = {
  [ChainId.UNDEFINED]: undefined,
  [ChainId.ETHEREUM_MAINNET]: 'wOMI',
  [ChainId.ETHEREUM_TESTNET]: 'wOMI',
  [ChainId.GOCHAIN_MAINNET]: 'GO OMI',
  [ChainId.GOCHAIN_TESTNET]: 'GO OMI',
}

export const getTokenName = (chainId: ChainId) =>
  throwIfUndefined(tokenNames[chainId])
